// ============================================================================
// Scaffolding
// ============================================================================

body {

}

a[data-scroll] {
  cursor: pointer;
}

.strong {
  font-weight: bold;
}

.fake-link {
  color: #0000FF;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.img-centered {
  &.img-responsive {
  margin-left: auto;
  margin-right: auto;
  }
  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-restricted {
  &.img-responsive {
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
  .img-responsive {
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
}

.map {
  margin-top: 20px;
  margin-bottom: 0px;
  min-height: 200px;
  @media (min-width: $screen-sm-min) {
    min-height: 400px;
  }
}