// ============================================================================
// Browser Update dot Org
// ============================================================================

#buorg {
  background-color: white;
  padding: 5px;
  border-bottom: 3px solid red;
  box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.3);

  #buorgclose {
    top: 5px;
  }
}