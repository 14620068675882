// ============================================================================
// Buttons
// ============================================================================

.btn-theme-primary {
  @extend .btn;
  white-space: normal !important;
  word-wrap: break-word;
  transition: all ease 500ms;
  &:hover, &:focus, &:active {
    border: 0;
    outline: 0;
  }
}