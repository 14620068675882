// ============================================================================
// Footer
// ============================================================================

.footer {
  a {
    color: $gray-light;
  }
  p {
    color: $gray-light;
  }
}
