// ============================================================================
// Overrides
// ============================================================================


#ForgotPassword {
  display: inline-block;
  margin: 20px 10px;
}

#__bs_notify__ {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 !important;
}

.w-lightbox-backdrop {
  z-index: 9000 !important;
}

.gallery-image {
  margin-bottom: 15px;
}

.overlay-bottom, .overlay-to-top, .overlay-top {
  background-color: rgba(0,0,0,.7);
  p.lead {
    font-weight:bold;
    text-transform: uppercase;
    font-size:16px;
  }
}
.overlay-container .text {
  padding:0;
}

.btn-gray-transparent:hover,
.btn-gray-transparent:focus,
.btn-gray-transparent.focus,
.btn-gray-transparent:active,
.btn-gray-transparent.active,
.open > .dropdown-toggle.btn-gray-transparent  {
  background-color: $brand-blue;
  border-color: $brand-blue;
}
.overlay-link i:hover {
  background-color: $brand-blue;
  color:#FFFFFF;
}

.service-carousel {
  margin-bottom:20px;
  .slides li, .slideshow {
    min-height:300px;
  }
}

.tp-bannertimer {
  height:5px;
  background-color:$brand-blue;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.header.dark, .fixed-header-on .dark.header.fixed {
  background-color:#222;

}
.header-top.dark {
  background-color:$brand-blue;
  padding:0;
  margin:0;
  a {
    color:#FFFFFF;
    font-size:22px;
  }
  .list-inline {
    float:right;
    padding-right:130px;
    margin:1px 0;
  }
}

.fixed-header-on .header .logo img {
  max-height:60px;
}

.sidebar {
  margin-top:30px;
}

@media (max-width: 767px) {
  .header-left {
    padding:0;
  }
  .dark.header .navbar.navbar-default {
    background-color: transparent;
    border:none;
  }
  .navbar-header #logo img {
    max-height:50px;
  }
}


@media (min-width: 768px) {
  .navbar-header {
    max-width: 300px;
  }
}
